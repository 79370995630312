.btn-upload-file-gallery{
  color: #fff;
  cursor: pointer;
  background-color: rgb(96, 1, 208, .82);
  backdrop-filter: saturate(200%) blur(22px) !important;
  -webkit-backdrop-filter: saturate(200%) blur(22px) !important;
  transition: .3s all;

  display: flex;
  flex-direction: row;
  gap: 10px;

  &:hover {
    background-color: var(--color-hover-primary);
  }
}