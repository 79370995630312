.body-settings-company{
  
}

.header-main-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0rem 1rem;
}

.main-body-settings-company{
  width: 100%;
  height: 100%;
  padding: 0rem 1rem;
}

.main-menu-settings-company{
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem;
}

.account-company{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px Solid #ececec;
}

.container-widget-storage{
  padding: 0px 0px 5px 0px;
  border-bottom: 1px Solid #ececec;
}

.list-menu-settings-company{
  padding: 10px 1rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.button-list-settings-company{
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: none;
  background-color: transparent;
  padding: 6px 10px;
  transition: .3s all;
  cursor: pointer;

  &:hover{
    background-color: #ececec;
  }
}

.container-button{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
}