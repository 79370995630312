.content-title{
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > img {
    display: none;
    height: 28px;
    width: auto;   
  }
}

.title-dashboard{
  font-size: var(--font-size-28);
  font-weight: var(--font-weight-600);
}

.content-view{
  flex-grow: 1;
}

.backdrop-bottom-sheet{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.0);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: -1;
  transition: .3s all;

  &.show {
    z-index: 4;
    overflow-y: hidden;
  }
}

.text-area-input{
  width: 100%;
  border: 1px Solid #d1d1d1;
  border-radius: 8px;
  padding: .75rem;
  resize: none;

  &:focus{
    border: 1px Solid var(--color-primary);
  }
}

.tab-view-container{
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.tab-view{
  background-color: #fff;
  padding: .35rem .35rem;
  border-radius: 20px;
}

.btn-tab-view{
  border: none;
  background-color: #fff;
  padding: .35rem .5rem;
  border-radius: 13px;
  transition: .3s all;
  cursor: pointer;

  &:hover{
    background-color: #F6F6F6;
  }

  &.active{
    background-color: #F6F6F6;
  }
}

.question-list{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.container-question{
  display: flex;
  flex-direction: row;
}

.question-div{
  flex: 50%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input-div{
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-question{
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-400);
  color: #000000;
}

.text-description{
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-300);
  color: #272727;
}

.text-example{
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-300);
  color: #525252;
}

.invalid-answer{
  border: 2px Solid var(--color-cancel) !important;
}

.footer-process-form{
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn-process-answer{
  background-color: var(--color-success);
  font-size: var(--font-size-16);
  padding: .5rem .75rem;
  border-radius: 10px;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: .3s all;

  &:hover{
    background-color: var(--color-hover-success);
  }
}

.grid-cards{
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 10px;
}

.header-modal-create-post{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (min-width: 1px) and (max-width: 249px) {
  .content-title{
    margin-top: .5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
    & > img {
      display: block;
    }
  }

  .container-question{
    flex-direction: column;
    gap: 10px;
  }

  .input-field{
    min-width: 100% !important; 
  }
}

@media screen and (min-width: 250px) and (max-width: 370px) {
  .content-title{
    margin-top: .5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
    & > img {
      display: block;
    }
  }

  .container-question{
    flex-direction: column;
    gap: 10px;
  }

  .input-field{
    min-width: 100% !important; 
  }
}


/* Estilos para dispositivos móviles pequeños */
@media screen and (min-width: 371px) and (max-width: 575px) {
  .content-title{
    margin-top: .5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
    & > img {
      display: block;
    }
  }

  .container-question{
    flex-direction: column;
    gap: 10px;
  }

  .input-field{
    min-width: 100% !important; 
  }
}

/* Estilos para dispositivos móviles y tablets */
@media screen and (min-width: 576px) and (max-width: 767px) {
  .content-title{
    margin-top: .5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
    & > img {
      display: block;
    }
  }

  .container-question{
    flex-direction: column;
    gap: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .content-title{
    margin-top: .5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
    & > img {
      display: block;
    }
  }

  .container-question{
    flex-direction: column;
    gap: 10px;
  }
}