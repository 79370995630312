.sign-up-container{
  display: flex;
  flex-direction: row;
  padding: 2rem;
  height: 100vh;
  background-color: var(--bg-white);
}

.sign-up-form-container{
  flex: 35;
}

.signin-btn{
  font-size: 14px !important;
}

.signup-p-form{
  padding-left: 5rem;
  padding-right: 5rem;
}

.sign-up-img-container{
  background-position: center;
  background-size: cover;
  flex: 65;
  overflow: hidden;
  border-radius: 2rem;
  transition: .3s all;
  cursor: pointer;
}

.sign-up-img-container:hover {
  transform: scale(1.001);
  filter: blur(2px);
}

.list-requeriments{
  margin-left: 1.5rem;
}

.signup-back-btn{
  color: var(--color-dark-primary);
  background-color: #fff;
  padding: .5rem;
  border: none;
  border-radius: .5rem;
  cursor: pointer;
  transition: .3s all;
}

.signup-back-btn:hover{
  background-color: #d4d4d4;
  color: #000;
}

.card-option-offer{
  background-color: #fff;
  border: none;
  border-radius: .5rem;
  padding: .5rem 1rem;
  cursor: pointer;
  transition: .3s all;
}

.offer-active{
  border: 1px Solid var(--color-secundary);
}

.img-card{
  width: 56px;
  margin-right: 15px;
}

.img-card-option-offer{
  height: 56px;
  width: auto;
}

.text-card{
  flex: 1;
}

.title-card-option-offer{
  color: var(--color-primary) !important;
}

.description-card-option-offer{
  color: var(--color-dark-tertiary);
}

.list-options {
  text-align: left;
  margin-top: 1rem;
  padding: 0rem 0rem 0rem 70px;
  transition: .3s all;
}

.list-options-closed{
  max-height: 0;
  overflow: hidden;
  padding: 0rem 0rem 0rem 70px;
  transition: max-height 0.5s ease-out;
}

.text-more-optiones{
  font-size: var(--font-size-14);
  color: #3d3d3d;
}

.img-logo-sign-up{
  height: 35px;
  width: auto;
}

.div-register-user-success{
  max-width: 980px;
}

/* Estilos para dispositivos móviles pequeños */
@media screen and (max-width: 575px) {
  .sign-up-img-container{
    flex: 0;
  }
  
  .sign-up-form-container{
    flex: 100;
  }

  .signup-p-form{
    padding: 0;
  }
}

/* Estilos para dispositivos móviles y tablets */
@media screen and (min-width: 576px) and (max-width: 767px) {
  .sign-up-img-container{
    flex: 0;
  }
  
  .sign-up-form-container{
    flex: 100;
  }

  .signup-p-form{
    padding: 0;
  }
}

/* Estilos para tablets y pantallas pequeñas de escritorio */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .sign-up-img-container{
    flex: 0;
  }
  
  .sign-up-form-container{
    flex: 100;
  }

  .signup-p-form{
    padding: 0;
  }
}

/* Estilos para pantallas de escritorio medianas */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .sign-up-img-container{
    flex: 40;
  }
  
  .sign-up-form-container{
    flex: 60;
  }

  .signup-p-form{
    padding: 1rem;
  }
}

/* Estilos para pantallas de escritorio grandes y televisores */
@media screen and (min-width: 1200px) {
  .signup-p-form{
    padding: 1rem;
  }
}