.container-pull-down{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.pull-down{
  width: fit-content;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: .25rem .75rem;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: .3s all;
  
  &:hover{
    background-color: #dadada;
  }
}

.container-options-pull-down{
  width: max-content;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -70px;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: transparent;
  z-index: 999999;

  & > button {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    padding: .2rem 1rem .2rem .5rem;
    background-color: transparent;
    border: none;
    border-top: 1px Solid #E6E6E6;
    cursor: pointer;
    transition: .3s all;
    gap: .75rem;

    &:hover{
      background-color: #dadada81;
    }
  }

  & > button:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top: none !important;
  }

  & > button:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

.empty-icon{
  width: 24px;
  height: 24px;
}
