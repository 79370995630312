.bg-upgrade-plan {
    background-image: url("../../../../../public/assets/images/bg-gold.jpg");
    filter: brightness(0.85);
    background-size: cover;
    color: #000;
    border: 2px solid #e4ca7e;
    cursor: pointer;
    outline: none;
    transition: .3s all;
    
    &:hover {
      filter: brightness(1);
      border-color: #e4ca7e;
      box-shadow: 0 0 5px #e4ca7e, 0 0 5px #e4ca7e, 0 0 8px #e4ca7e, 0 0 8px #e4ca7e;
    }
}