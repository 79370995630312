.btn-delete-file{
  background-color: rgba(255, 0, 0, 0.7);
  padding: 2px;
  border: none;
  cursor: pointer;
  z-index: 15;
  transition: .3s all;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  & > svg {
    font-size: 20px;
    color: #FFF;
    transition: .3s all;
  }

  &:hover{
    background-color: rgb(180, 0, 0);

    & > svg {
      color: #fff;
    }
  }
}