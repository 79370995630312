.calendar-content{
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
}

.rbc-row-content {
  z-index: 1 !important;
}

.detail-modal-content-calendar{
  padding: 2.5rem 0rem 0rem 0rem !important;
}

.card-image{
  transition: .3s all;
  border: none;

  &.pressed{
    transform: scale(0.95);
  }
}

.container-buttons-cc{
  display: flex;
  flex-direction: row;
  align-content: flex-end;

  & > button {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 15px;
  }
}

.container-upload-file{
  display: flex;
  place-content: center;
  margin-bottom: 1rem;
  flex: 1;
  justify-content: center;
  align-items: center;

  & > img {
    border-radius: 12px;
  }
}

.loading-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.btn-upload-file{
  background-color: var(--color-secundary);
  display: block;
  text-align: center;
  padding: 0.25rem;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  transition: .3s all;

  &:hover{
    background-color: var(--color-hover-secundary);
  }
}

.file-container{
  background-color: '';
  border-radius: 12px;
  min-height: 400px;
  width: calc(90vw - 44px);
  max-width: 700px;
  display: grid;
  place-content: center;
}

.container-calendar{
  flex: 1;
}

.btn-upload-filea-calendar-action{
  background-color: transparent;
  color: #000;
  border: none;
  height: 46px;
  font-size: 14px;
  width: 100%;
  display: grid;
  place-items: center;
  border-radius: 8px;
  padding: 0px 8px;
  cursor: pointer;
  transition: .3s all;

  &:hover {
    background-color: hsla(0, 0%, 0%, 0.1);
  }
}

.btn-create-post-calendar-action{
  background-color: var(--color-primary);
  color: #e4e4e4;
  border: none;
  height: 46px;
  border-radius: 8px;
  padding: 0px 8px;
  cursor: pointer;
  transition: .3s all;

  &:hover {
    background-color: var(--color-hover-primary);
  }
}

.grid{
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 2fr;
  grid-gap: 10px;
}

.img-list-item{
  width: 100%;
  height: auto;
  border-radius: 5px;
  cursor: pointer;
  transition: .3s all;

  &:hover{
    filter: brightness(0.5);  
  }
}

.close-btn{
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #e6e6e6;
  border: none;
  color: #a7a7a7;
  font-size: var(--font-size-24);
}

.float-button-mobile{
  display: none;
  z-index: 2;
  height: 42px;
  width: 100%;
  border-radius: 18px;
  font-size: var(--font-size-36);
  color: #0000009c;
  cursor: pointer;

  &.ios-float-button{
    bottom: 92px !important;
  }

  .container-float-btn{
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
  
    & > button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 5px;
      border: none;
      flex: 50%;
      height: 100%;
      background-color: transparent;
      font-size: var(--font-size-16);
      font-weight: 500;
      z-index: 1;
      color: #000000b4;
      cursor: pointer;
      transition: .3s all;

      &:hover{
        color: #5656569c;
      }
    }

    & > button:last-child {
      color: var(--bg-white);
      background-color: var(--color-primary);
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
      transition: .3s all;

      &:hover{
        background-color: var(--color-hover-primary);
      }
    }
  }
}

@media screen and (min-width: 1px) and (max-width: 249px) {
  .close-btn{
    width: 30px;
    height: 30px;
    font-size: var(--font-size-20);
  }

  .calendar-content{
    flex-direction: column;
  }
  
  .container-calendar{
    margin-left: 0px;
    height: 100% !important;
    max-width: calc(100vw - 44px);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .float-button-mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-30);
  }
  .container-buttons-cc{
    display: none;
  }
}

@media screen and (min-width: 250px) and (max-width: 370px) {
  .close-btn{
    width: 30px;
    height: 30px;
    font-size: var(--font-size-20);
  }

  .calendar-content{
    flex-direction: column;
  }
  
  .container-calendar{
    margin-left: 0px;
    height: 100% !important;
    max-width: calc(100vw - 44px);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .float-button-mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-30);
  }
  .container-buttons-cc{
    display: none;
  }
}

/* Estilos para dispositivos móviles pequeños */
@media screen and (min-width: 371px) and (max-width: 575px) {
  .calendar-content{
    flex-direction: column;
  }
  
  .container-calendar{
    margin-left: 0px;
    flex: 100%;
    max-width: calc(100vw - 44px);
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  .float-button-mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-32);
  }
  .container-buttons-cc{
    display: none;
  }
}

/* Estilos para dispositivos móviles y tablets */
@media screen and (min-width: 576px) and (max-width: 767px) {
  .calendar-content{
    flex-direction: column;
  }
  
  .container-calendar{
    margin-left: 0px;
    height: 100% !important;
    max-width: calc(100vw - 44px);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .float-button-mobile{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .grid{
    grid-template-columns: repeat(3, 1fr);
  }
  .container-buttons-cc{
    display: none;
  }
}

/* Estilos para tablets y pantallas pequeñas de escritorio */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .calendar-content{
    flex-direction: column;
  }

  .container-calendar{
    margin-left: 0px;
    height: 100% !important;
    max-width: calc(100vw - 44px);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .float-button-mobile{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .grid{
    grid-template-columns: repeat(4, 1fr);
  }

  .container-buttons-cc{
    display: none;
  }
}

.event-day-for-facebook{
  background-color: #3B5998 !important;
  color: #fff;

  .rbc-event-content{
    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url('../../../../../public/assets/icons/white-facebook.svg');
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}

.event-day-for-linkedin{
  background-color: #0e76a8 !important;
  color: #fff;

  .rbc-event-content{
    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url('../../../../../public/assets/icons/white-linkedin.svg');
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}

.event-day-for-instagram{
  color: #fff;
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );

  .rbc-event-content{
    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url('../../../../../public/assets/icons/white-instagram.svg');
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}
.event-day-for-twitter{
  background-color: #1DA1F2 !important;
  color: #fff;

  .rbc-event-content{
    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url('../../../../../public/assets/icons/white-twitter.svg');
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}
.event-day-for-tiktok{
  background-color: #000 !important;
  color: #fff;

  .rbc-event-content{
    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url('../../../../../public/assets/icons/white-tiktok.svg');
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}
.event-day-for-youtube{
  background-color: #FF0000 !important;
  color: #fff;

  .rbc-event-content{
    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url('../../../../../public/assets/icons/white-youtube.svg');
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}
.event-day-for-text{
  background-color: #4c3b98 !important;
  color: #fff;
  
  .rbc-event-content{
    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url('../../../../../public/assets/icons/white-write.svg');
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: middle;
      margin-right: 8px;
      margin-top: -2px;
    }
  }
}
.event-day-for-video{
  background-color: #FFAA00 !important;
  color: #fff;

  .rbc-event-content{
    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url('../../../../../public/assets/icons/white-video.svg');
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: middle;
      margin-right: 8px;
      margin-top: -2px;
    }
  }
}

/* Estilos para pantallas de escritorio medianas */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .responsive-divider-action-calendar{
    display: none;
  }
}

/* Estilos para pantallas de escritorio grandes y televisores */
@media screen and (min-width: 1200px) {
  .responsive-divider-action-calendar{
    display: none;
  }
}

.btn-switch-type-post-generate{
  border: none;
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  padding: .25rem .75rem;
  transition: .3s all;

  &:hover{
    background-color: var(--color-hover-primary);

    & > span{
      color: #fff;
    }
  }

  &.active{
    background-color: var(--color-hover-primary) !important;

    & > span{
      color: #fff !important;
    }
  }
}