.sign-in-container{
  display: flex;
  flex-direction: row;
  padding: 2rem;
  height: 100vh;
  background-color: var(--bg-white);
}

.ios-signin-container{
  height: calc(100vh - 47px) !important;
}

.sign-in-form-container{
  flex: 35;
}

.signin-p-form{
  padding-left: 5rem;
  padding-right: 5rem;
}

.signin-title{
  font-size: 36px;
  font-weight: var(--font-weight-700);
  color: var(--color-dark-primary);
}

.signin-btn{
  background-color: var(--color-dark-primary);
  color: #fff;
  padding: .75rem 0rem;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: .3s all;
}

.signin-btn:hover{
  background-color: var(--color-primary);
}

.create-account-btn{
  background-color: var(--color-secundary);
  text-decoration: none;
  color: #fff;
  padding: .5rem 0rem;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: .3s all;
}

.create-account-btn:hover{
  color: #000;
  background-color: var(--color-hover-secundary);
}

.background-signin{

  height: 100%;
  width: 100%;
  border-radius: 1.5rem;
  transition: .3s all;
}

.sign-in-img-container{
  background-position: center;
  background-size: cover;
  flex: 65;
  overflow: hidden;
  border-radius: 2rem;
  transition: .3s all;
  cursor: pointer;
}

.sign-in-img-container:hover {
  transform: scale(1.001);
  filter: blur(2px);
}

.img-logo-sign-in{
  height: 35px;
  width: auto;
}

/* Estilos para dispositivos móviles pequeños */
@media screen and (max-width: 575px) {
  .sign-in-img-container{
    flex: 0;
  }
  
  .sign-in-form-container{
    flex: 100;
  }

  .signin-p-form{
    padding: 0;
  }
}

/* Estilos para dispositivos móviles y tablets */
@media screen and (min-width: 576px) and (max-width: 767px) {
  .sign-in-img-container{
    flex: 0;
  }
  
  .sign-in-form-container{
    flex: 100;
  }

  .signin-p-form{
    padding: 0;
  }
}

/* Estilos para tablets y pantallas pequeñas de escritorio */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .sign-in-img-container{
    flex: 0;
  }
  .sign-in-form-container{
    flex: 100;
  }
  .signin-p-form{
    padding: 0;
  }
}

/* Estilos para pantallas de escritorio medianas */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .sign-in-img-container{
    flex: 40;
  }
  .sign-in-form-container{
    flex: 60;
  }
  .signin-p-form{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* Estilos para pantallas de escritorio grandes y televisores */
@media screen and (min-width: 1200px) {
  .signin-p-form{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}