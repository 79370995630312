.div-request-guest-invitation{
  gap: 10px;
  border-bottom: 1px Solid #eeeeee;
}

.btn-for-send-invitation-guest-user{
  padding: .5rem .75rem;
  color: #fff;
  border: none;
  border-radius: 6px;
  background-color: var(--color-primary);
  cursor: pointer;
  transition: .3s all;

  &:hover{
    background-color: var(--color-hover-primary);
  }
}

.container-guested-user{
  padding: 1rem 1rem 2rem 1rem;
  display: flex;
  flex-direction: column;
}

.item-guested-user{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0rem 1rem;
  border-radius: 8px;
  transition: .3s all;
  cursor: pointer;

  &:hover{
    background-color: #eeeeee;
  }
}

.user-container-guest-user{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  & > img {
    height: 24px;
    width: auto;
  }
}

.div-actions-guest-users{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.btn-delete-guest-user{
  display: flex;
  flex-direction: row;
  align-items: center;

  border: none;
  padding: 6px;
  background-color: transparent;
  cursor: pointer;

  & > svg{
    transition: .3s all;
  }
}