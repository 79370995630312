.div-container-buttons{
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;

  & > button {
    width: 50%;
  }
}

.form-login-invitation{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.logo-invitation{
  width: 220px;
  margin-bottom: 1rem;
}

.back-button{
  width: 200px;
}

@media screen and (min-width: 1px) and (max-width: 991px) {
  .div-container-buttons{
    flex-direction: column;
    gap: 25px;
    align-items: center;
  }  
}