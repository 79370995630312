.content-title{
  width: 100%;
  margin-bottom: 1rem;

  & > img {
    display: none;
    height: 28px;
    width: auto;   
  }
}

.title-dashboard{
  font-size: var(--font-size-28);
  font-weight: var(--font-weight-600);
}

.content-view{
  flex-grow: 1;
}

.container-cards-menu{
  max-width: 756px;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.card-settings{
  height: 56px;
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: .75rem;
  background-color: #fff;
  cursor: pointer;
}

.menu-main-container{
  height: 100%;
  width: 100%;
}

.header-submenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submenu-back-btn{
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-primary);
  font-weight: 300;
}

.submenu-back-btn, .submenu-next-btn{
  width: 62px;
}

.content-submenu{
  margin: 0 auto;
  width: 100%;
  max-width: 980px;
}

.section-description{
  background-color: #fff;
  border-radius: .73rem;
  margin-bottom: 1rem;
  padding: .5rem;
}

@media screen and (min-width: 1px) and (max-width: 249px) {

}

@media screen and (min-width: 250px) and (max-width: 370px) {

}

/* Estilos para dispositivos móviles pequeños */
@media screen and (min-width: 371px) and (max-width: 575px) {

}

/* Estilos para dispositivos móviles y tablets */
@media screen and (min-width: 576px) and (max-width: 767px) {

}

/* Estilos para tablets y pantallas pequeñas de escritorio */
@media screen and (min-width: 768px) and (max-width: 991px) {

}

/* Estilos para pantallas de escritorio medianas */
@media screen and (min-width: 992px) and (max-width: 1199px) {

}

/* Estilos para pantallas de escritorio grandes y televisores */
@media screen and (min-width: 1200px) {

}
