.main-loader{
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}