.alert {
  padding: 5px 10px;
  width: 250px;
  min-height: 50px;
  border: 1px solid rgb(220, 220, 220);
  border-radius: .75rem;
  position: fixed;
  bottom: 100px;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px;
  backdrop-filter: saturate(180%) blur(20px);
  transition: .3s all;

  &.show {
    opacity: 1;
    z-index: 99999999;
  }

  &.type-alert-danger,
  &.type-alert-success,
  &.type-alert-warning,
  &.type-alert-copy, 
  &.type-alert-info, 
  &.type-alert-loading {
    border: none;
    color: white;

    &.type-alert-danger {
      background-color: rgba(255, 30, 30, 0.682);
    }

    &.type-alert-success {
      background-color: rgba(29, 163, 36, 0.682);
    }

    &.type-alert-warning {
      background-color: rgba(238, 152, 23, 0.682);
    }

    &.type-alert-copy {
      background-color: rgba(0, 213, 228, 0.682);
    }
    &.type-alert-info {
      background-color: rgba(97, 233, 243, 0.682);
      color: #000;
    }
    &.type-alert-loading {
      background-color: rgba(223, 223, 223, 0.682);
      color: #000;
    }
  }

  .alert-body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex: 1;

    & > img {
      width: 24px;
      height: auto;
    }
  }

  .alert-button {
    width: 100%;
    border-top: 1px solid rgb(225, 225, 225);
  }

  .btn-close-alert {
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 5px 0rem;
    cursor: pointer;
    transition: .3s all;

    &:hover {
      background-color: rgba(236, 236, 238, 0.72);
    }
  }
}