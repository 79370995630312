.container-brand-profile {
  margin: 0 auto;
  width: 100%;
  max-width: 980px;
}

.container-brand{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card-brand-profile{
  min-height: 300px;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.content-brand-card{
  flex: 1;
}

.logo-container, .palette-color-container, .typography-container{
  display: flex;
  flex-direction: row;

  & > div{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.taglines-container{
  display: flex;
  flex-direction: row;

  & > div{
    display: flex;
    justify-content: center;
  }
}

.selected-container{
  flex: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.options-container{
  flex: 60%;
  display: flex;
  flex-direction: row;
}

.logo-div{
  & > img {
    height: 250px;
    width: auto;
  }
}

.color-div{
  & > div {
    height: 200px;
    width: 200px;
  }
}

.selected-typography{
  font-size: var(--font-size-20);
}

.color-item-selected{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option-logo, .option-palette-color, .option-typography{
  flex: 20%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  & > img {
    width: calc(100% - 60px);
    height: auto;
  }
}

.option-tagline{
  padding: .5rem 1rem;
  border: none;
  // background-color: transparent;
  height: min-content !important;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  align-items: center;
  gap: 10px;
}

.option-palette-color{
  padding-left: 10px;
  padding-right: 10px;
}

.color-list{
  display: grid;
  gap: 5px;
  grid-template-columns: 2fr 2fr;
  width: 50%;
  height: 100%; /* Ajusta la altura del contenedor */
}

.color-item{
  width: 100%;
  height: 60px;
  border-radius: 10px;
}

.indicator-option{
  height: 10px;
  width: 30px;
  border-radius: 20px;
  background-color: #dddddd;
}

.indicator-option-tagline{
  width: 15px !important;
  height: 15px !important;
}

.brand-selected{
  background-color: var(--color-success) !important;
}

.tagline-options-container{
  padding: 0px 30px;
  flex-direction: column !important;
  justify-content: space-evenly !important;
}

@media screen and (min-width: 1px) and (max-width: 249px) {
  .logo-container, .palette-color-container, .typography-container {
    flex-direction: column;
    gap: 20px;

    & > .logo-div{
      padding-bottom: 1.5rem;
    }
  }

  .taglines-container{
    padding: 0;
    flex-direction: column;
    gap: 20px;
  }

  .selected-container{
    flex: 100%;
  }
  
  .options-container{
    flex: 100%;
    flex-direction: column;
    gap: 10px;
  }

  .logo-div{
    flex: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    & > img {
      height: 190px;
      width: auto;
    }
  }
  .option-palette-color{
    width: 100%;
  }

  .option-logo{
    flex: 100%;
  
    & > img {
      height: 60px;
      width: auto;
    }
  }

  .color-div{
    padding: 15px 0px;
  }

  .color-list{
    width: 85%;
  }
}

@media screen and (min-width: 250px) and (max-width: 370px) {
  .logo-container, .palette-color-container, .typography-container {
    flex-direction: column;
    gap: 20px;

    & > .logo-div{
      padding-bottom: 2rem;
    }
  }

  .taglines-container{
    flex-direction: column;
    gap: 20px;
  }

  .tagline-options-container{
    padding: 0px;
  }

  .selected-container{
    flex: 100%;
  }
  
  .options-container{
    flex: 100%;
    flex-direction: column;
    gap: 10px;
  }

  .logo-div{
    flex: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    & > img {
      height: 200px;
      width: auto;
    }
  }

  .option-palette-color{
    width: 100%;
  }
  
  .option-logo{
    flex: 100%;
  
    & > img {
      height: 70px;
      width: auto;
    }
  }

  .color-div{
    padding: 15px 0px;
  }

  .color-list{
    width: 85%;
  }
}

/* Estilos para dispositivos móviles pequeños */
@media screen and (min-width: 371px) and (max-width: 575px) {
  .logo-container, .palette-color-container, .typography-container {
    flex-direction: column;
    gap: 20px;

    & > .logo-div{
      padding-bottom: 2rem;
    }
  }

  .taglines-container{
    padding: 0;
    flex-direction: column;
    gap: 20px;
  }

  .selected-container{
    flex: 100%;
  }
  
  .options-container{
    flex: 100%;
    flex-direction: row;
  }

  .logo-div{
    flex: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    & > img {
      height: 240px;
      width: auto;
    }
  }

  .option-palette-color{
    width: 100%;
  }
  
  .option-logo{
    flex: 100%;
  
    & > img {
      height: 80px;
      width: auto;
    }
  }

  .color-div{
    padding: 15px 0px;
  }

  .tagline-options-container{
    padding: 0px;
  }

  .color-list{
    width: 85%;
  }
}

/* Estilos para dispositivos móviles y tablets */
@media screen and (min-width: 576px) and (max-width: 767px) {
  .logo-container, .palette-color-container, .typography-container {
    flex-direction: column;
    gap: 20px;

    & > .logo-div{
      padding-bottom: 2rem;
    }
  }

  .taglines-container{
    padding: 0;
    flex-direction: column;
    gap: 20px;
  }

  .selected-container{
    flex: 100%;
  }
  
  .options-container{
    flex: 100%;
    flex-direction: row;
  }

  .logo-div{
    flex: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    & > img {
      height: 280px;
      width: auto;
    }
  }
  
  .option-palette-color{
    width: 100%;
  }

  .option-logo{
    flex: 100%;
  
    & > img {
      height: 90px;
      width: auto;
    }
  }

  .tagline-options-container{
    padding: 0px;
  }

  .color-div{
    padding: 15px 0px;
  }

  .color-list{
    width: 85%;
  }
}

/* Estilos para tablets y pantallas pequeñas de escritorio */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .logo-container, .palette-color-container, .typography-container {
    flex-direction: column;
    gap: 20px;

    & > .logo-div{
      padding-bottom: 2rem;
    }
  }

  .taglines-container{
    padding: 0;
    flex-direction: column;
    gap: 20px;
  }

  .selected-container{
    flex: 100%;
  }
  
  .options-container{
    flex: 100%;
    flex-direction: row;
  }

  .logo-div{
    flex: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    & > img {
      height: 280px;
      width: auto;
    }
  }

  .tagline-options-container{
    padding: 0px;
  }
  
  .option-logo, .option-palette-color{
    flex: 100%;
  
    & > img {
      height: 90px;
      width: auto;
    }
  }
}

/* Estilos para pantallas de escritorio medianas */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  /* Agrega tus estilos aquí */
}

/* Estilos para pantallas de escritorio grandes y televisores */
@media screen and (min-width: 1200px) {
  /* Agrega tus estilos aquí */
}


.card_social {
  height: 50px;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: .3s all;
}

.card_social:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.cards_container {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.card_objetive {
  height: 50px;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: .3s all;
}

.card_objetive:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

input[type="checkbox"] {
  display: none;
}

.card-label {
  padding: 0rem 1rem;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.card-content-label{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.card-content {
  /* Estilos de contenido de la tarjeta */
}

.title-objetive {
  font-weight: 500;
}

.description-objetive {
  font-weight: 300;
}

.card-objetive-Professional {
  transition: .3s all;
}

.card-objetive-Professional:hover {
  background-color: #000080;
  color: white;
}

.card-checkbox-Professional:checked + .card-label {
  background-color: #000080;
  color: white;
}

.card-objetive-Friendly {
  transition: .3s all;
}

.card-objetive-Friendly:hover {
  background-color: #FFFF00;
}

.card-checkbox-Friendly:checked + .card-label {
  background-color: #FFFF00;
}

.card-objetive-Inspirational {
  transition: .3s all;
}

.card-objetive-Inspirational:hover {
  background-color: #b785d4;
  color: black;
}

.card-checkbox-Inspirational:checked + .card-label {
  background-color: #b785d4;
  color: black;
}

.card-objetive-Authoritative {
  transition: .3s all;
}

.card-objetive-Authoritative:hover {
  background-color: #800080;
  color: white;
}

.card-checkbox-Authoritative:checked + .card-label {
  background-color: #800080;
  color: white;
}

.card-objetive-Witty_Humorous {
  transition: .3s all;
}

.card-objetive-Witty_Humorous:hover {
  background-color: #FFA500;
}

.card-checkbox-Witty_Humorous:checked + .card-label {
  background-color: #FFA500;
}

.card-objetive-Innovative {
  transition: .3s all;
}

.card-objetive-Innovative:hover {
  background-color: #00FFFF;
}

.card-checkbox-Innovative:checked + .card-label {
  background-color: #00FFFF;
}

.card-objetive-Casual {
  transition: .3s all;
}

.card-objetive-Casual:hover {
  background-color: #bcf3ed;
}

.card-checkbox-Casual:checked + .card-label {
  background-color: #bcf3ed;
}

.card-objetive-Passionate {
  transition: .3s all;
}

.card-objetive-Passionate:hover {
  background-color: #FF0000;
  color: white;
}

.card-checkbox-Passionate:checked + .card-label {
  background-color: #FF0000;
  color: white;
}

.card-objetive-Educational {
  transition: .3s all;
}

.card-objetive-Educational:hover {
  background-color: #088d08;
}

.card-checkbox-Educational:checked + .card-label {
  background-color: #088d08;
}

.card-objetive-Sophisticated {
  transition: .3s all;
}

.card-objetive-Sophisticated:hover {
  background-color: #f3d841;
}

.card-checkbox-Sophisticated:checked + .card-label {
  background-color: #f3d841;
}

.card-multicheckbox-Facebook {
  transition: .3s all;
}

.card-multicheckbox-Facebook:hover {
  background-color: #3b5998;
  color: white;
}

.card-multicheckbox-Facebook:checked + .card-label {
  background-color: #3b5998;
  color: white;
}

.card-multicheckbox-Instagram {
  transition: .3s all;
}

.card-multicheckbox-Instagram:hover {
  background: linear-gradient(to left, #405DE6, #5851DB, #833AB4, #C13584, #e13056, #fd1d1d, #f88418f1);
  color: white;
}

.card-multicheckbox-Instagram:checked + .card-label {
  background: linear-gradient(to left, #405DE6, #5851DB, #833AB4, #C13584, #e13056, #fd1d1d, #f88418f1);
  color: white;
}

.card-multicheckbox-Linkedin {
  transition: .3s all;
}

.card-multicheckbox-Linkedin:hover {
  background-color: #1A81B9;
  color: white;
}

.card-multicheckbox-Linkedin:checked + .card-label {
  background-color: #1A81B9;
  color: white;
}

.card-multicheckbox-Tiktok {
  transition: .3s all;
}

.card-multicheckbox-Tiktok:hover {
  background-color: #000000;
  color: white;
}

.card-multicheckbox-Tiktok:checked + .card-label {
  background-color: #000000;
  color: white;
}

.card-multicheckbox-Twitter {
  transition: .3s all;
}

.card-multicheckbox-Twitter:hover {
  background-color: #5AA4EB;
  color: white;
}

.card-multicheckbox-Twitter:checked + .card-label {
  background-color: #5AA4EB;
  color: white;
}

.card-multicheckbox-Google {
  transition: .3s all;
}

.card-multicheckbox-Google:hover {
  background-color: #fff;
  color: #000;
}

.card-multicheckbox-Google:checked + .card-label {
  background-color: #fff;
  color: #000;
}

.card-multicheckbox-YouTube:hover{
  background-color: #ee1616;
  color: #ffffff;
}

.card-multicheckbox-YouTube:checked + .card-label {
  background-color: #ee1616;
  color: #ffffff;
}
  