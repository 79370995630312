.multi-select-option-container{
  margin: 1rem 4rem;
  border-radius: 8px;
  background-color: #fff;

  & > button {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    padding: .25rem 1rem .25rem 1rem;
    background-color: transparent;
    border: none;
    border-top: 1px Solid #E6E6E6;
    cursor: pointer;
    transition: .3s all;
    gap: 1.25rem;

    &:hover{
      background-color: #dadada81;
    }
  }

  & > button:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top: none !important;
  }

  & > button:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

.container-data-multi-select-option{
  display: flex;
  flex-direction: row;
  gap: 10px;

  & > img {
    width: 24px;
    height: auto;
  }
}