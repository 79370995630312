.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.navbar-title{
  margin-bottom: 4rem;
  padding-left: 10px;
}

.active-link{
  color: #f8f8f8 !important;
  background-color: var(--color-primary);
  transition: .3s all;

  &:hover{
    background-color: var(--color-hover-primary);
  }
}

.img-logo-navbar {
  height: 34px;
  width: auto;
}

.sidebar-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1; /* Ocupa el espacio restante en sidebar-content */
}

.menu-content{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menu-link{
  height: 46px;
  cursor: pointer !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  transition: .3s all;
  padding: 0px 8px;
  outline: 0;
  user-select: none; /* Evita que el texto del enlace sea seleccionable */
  touch-action: manipulation; /* Deshabilita el comportamiento táctil predeterminado */
  text-decoration: none;

  & > span {
    font-size: 16px;
    margin-left: 10px;
  }
}

.a-link{
  cursor: pointer !important;

  &:active,
  &:focus {
    outline: none; /* Elimina el contorno de enfoque en clic y enfoque */
    box-shadow: none; /* Elimina cualquier sombra del enfoque */
    -webkit-tap-highlight-color: transparent; /* Deshabilita el sombreado en dispositivos iOS */
  }
}

.icon-menu{
  color: var(--color-primary);
  font-size: 31px !important;
}

.icon-menu-active{
  color: #fff !important;
}

.menu-active{
  background-color: var(--color-primary) !important;
  color: #fff !important;
}

.menu-active-mobile{
  background-color: transparent !important;
  color: var(--color-primary) !important;
}


.icon-mobile{
  color: #280055 !important;
}

.icon-menu-mobile-active{
  color: var(--color-primary) !important;
}

@media (hover: none), (pointer: coarse) {
  /* Elimina el efecto de hover en dispositivos táctiles o en modo responsive */
  .menu-link:hover {
    background-color: transparent;
  }
}

.settings-menu{
  display: none;
}

.account-content{
  display: flex;
  flex-direction: column;
}

.settings-container{
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  transition: .3s all;
  cursor: pointer;
  padding: 0px 8px;
}

.help-container,
.account-container
{
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  transition: .3s all;
  cursor: pointer;
  padding: 0px 8px;

  &:hover {
    background-color: hsla(0, 0%, 0%, 0.1);
  }
}

.divider{
  height: 1px;
  border-top: 1px Solid #e4e4e4;
  margin: 10px 0px;
}

.img-account-content-sidebar{
  height: 24px;
  width: 24px;
}

.content-account-sidebar{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-account-sidebar > span {
  margin-left: 10px;
  font-size: var(--font-size-14);
}

.title-submenu{
  font-weight: 500;
}

.logout-btn{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  color: #fff;
  transition: .3s all;
  margin-top: 1rem;
  
  &:hover {
    background-color: var(--color-hover-primary);
  }
}

.icon-ios{
  font-size: 28px !important; 
}

@media screen and (min-width: 1px) and (max-width: 249px) {
  .navbar-title,
  .account-content,
  .divider
  {
    display: none;
  }

  .menu-content{
    flex-direction: row;
    justify-content: center;
  }

  .settings-menu{
    display: flex;
  }

  .menu-link {
    height: 100%;
    width: 75px;
    justify-content: center;
    flex-direction: column;

  }

  .ios-menu-link{
    height: 76% !important;
  }

  .menu-link > span {
    margin: 0px;
    font-size: 12px;
  }

  .icon-menu{
    font-size: 28px;
  }

  .active-link{
    color: var(--color-primary) !important;
    background-color: transparent;
  }
}


@media screen and (min-width: 250px) and (max-width: 370px) {
  .navbar-title,
  .account-content,
  .divider
  {
    display: none;
  }

  .menu-content{
    flex-direction: row;
    justify-content: center;
  }

  .settings-menu{
    display: flex;
  }

  .menu-link {
    height: 100%;
    width: 76px;
    justify-content: center;
    flex-direction: column;

  }
  .ios-menu-link{
    height: 76% !important;
  }

  .menu-link > span {
    margin: 0px;
    font-size: 12px;
  }

  .icon-menu{
    font-size: 28px;
  }

  .active-link{
    color: var(--color-primary) !important;
    background-color: transparent;
  }
}


/* Estilos para dispositivos móviles pequeños */
@media screen and (min-width: 371px) and (max-width: 575px) {
  .navbar-title,
  .account-content,
  .divider
  {
    display: none;
  }

  .menu-content{
    flex-direction: row;
    justify-content: center;
  }

  .settings-menu{
    display: flex;
  }

  .menu-link {
    height: 100%;
    width: 76px;
    justify-content: center;
    flex-direction: column;
  }

  .ios-menu-link{
    height: 76% !important;
  }

  .menu-link > span {
    margin: 0px;
    font-size: 12px;
  }

  .icon-menu{
    font-size: 30px;
  }

  .active-link{
    color: var(--color-primary) !important;
    background-color: transparent;
  }
}

/* Estilos para dispositivos móviles y tablets */
@media screen and (min-width: 576px) and (max-width: 767px) {
  .navbar-title,
  .account-content,
  .divider
  {
    display: none;
  }
  .menu-content{
    flex-direction: row;
    justify-content: space-around;
  }

  .settings-menu{
    display: flex;
  }

  .menu-link {
    height: 100%;
    width: 80px;
    justify-content: center;
    flex-direction: column;
  }

  .ios-menu-link{
    height: 78% !important;
  }

  .menu-link > span {
    margin: 0px;
    font-size: 14px;
  }

  .icon-menu{
    font-size: 31px;
  }

  .active-link{
    color: var(--color-primary) !important;
    background-color: transparent;
  }
}

/* Estilos para tablets y pantallas pequeñas de escritorio */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-title,
  .account-content,
  .divider
  {
    display: none;
  }

  .menu-content{
    flex-direction: row;
    justify-content: space-evenly;
  }
  
  .settings-menu{
    display: flex;
  }

  .menu-link {
    height: 100%;
  }

  .ios-menu-link{
    height: 80% !important;
  }
  
  .icon-menu{
    font-size: 31px;
  }
}
