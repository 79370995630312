.indicator-all-storage{
  height: 10px;
  width: 100%;
  border-radius: 6px;
  background-color: rgb(201, 201, 201);
  margin-bottom: .5rem;
}

.bar-indicator-storage{
  height: 100%;
  border-radius: 6px;
}

.cero-usage{
  background-color: rgb(58, 58, 247);
}

.fifty-usage{
  background-color: rgb(247, 187, 58);
}

.eighty-usage{
  background-color: rgb(247, 102, 58);
}

.ninety-usage{
  background-color: rgb(245, 53, 53);
}

.float-right{
  float: right;
}

.table-description-storage{
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
}

.item-description-storage{
  padding: 6px 10px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  transition: .3s all;

  &:hover{
    background-color: #ebebeb;
  }
}

.text-description-storage{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}