.image-container {
  position: relative;
}

.thumbnail-file{
  width: 100%;
  height: 100px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: .3s all;

  &:hover{
    filter: brightness(0.5);
  }
}

.backdrop-select{
  filter: brightness(0.2) !important;
}

@media screen and (min-width: 1px) and (max-width: 991px) {
  .thumbnail-file{
    height: 120px;
  }
}