.container-forgot-password{
  height: 100vh;
  width: 100%;
  background-color: rgba(245, 245, 245);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-content-forgot-password{
  width: 400px;
  background-color: rgba(250, 250, 250);
  border-radius: .5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  padding: 2.5rem;
}

.send-code-btn{
  background-color: var(--color-primary);
  text-decoration: none;
  color: #fff;
  padding: .5rem 0rem;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: .3s all;
}

.send-code-btn:hover{
  background-color: var(--color-hover-primary);
}

.title-forgot-password{
  color: var(--color-dark-tertiary);
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-700);
}

.text-forgot-password{
  color: var(--color-tertiary-pale);
  font-size: var(--text-xl-size);
  font-weight: var(--font-weight-400);
}