.container-gallery{
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

.btn-add-file{
  background-color: transparent;
  display: flex;
  align-items: center;

  & > svg{
    transition: .3s all;
    font-size: 28px;
  }

  &:hover{

    & > svg{
      fill: var(--color-hover-primary);
    }
  }
}

.float-button-gallery{
  z-index: 20;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.container-image-xl {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  transition: .3s all;
  overflow: hidden;
  
  & > img {
    border-radius: 6px;
    width: 100%;
    height: calc(100vh - 200px);
    object-fit: contain;
  }

  &:hover {
    &::after {
      content: '';
      border-radius: 6px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(22, 22, 22, 0.8) 0%, rgba(22, 22, 22, 0.1) 40%);
      pointer-events: none;
    }

    .btn-close-img-xl {
      color: #fff;
    }
  }
}

.btn-close-img-xl {
  color: #ffffff5d;
  transition: .3s all;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}