.btn-toogle{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 2px;
  height: 24px;
  width: 36px;
  border-radius: 25px;
  border: 1px Solid #dadada;
  background-color: #dadada;
  cursor: pointer;
  transition: .3s all;
  
  &.active{
    justify-content: flex-end;
    background-color: var(--color-success);
    border: 1px Solid var(--color-success);
  }
}

.circle-toogle{
  background-color: #fff;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  transition: .3s all;
}