.social-icon-header{
  height: 28px;
  width: 28px;
}

.p-modal{
  padding: 1.25rem 1.5rem;
}

.header-detail-post{
  display: block;
  margin-bottom: 1rem;
}

.detail-block{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.25rem;
}

.detail-block-img{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.textarea-detail-post{
  max-width: 100%;
}

.btn-select-file{
  padding: .25rem 1rem;
  margin: 2rem 0rem 1rem 0rem;
  background-color: #dadada;
  color: #000;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  transition: .3s all;

  &:hover{
    background-color: var(--color-primary);
    color: #fff;
  }
}

.demo-view-file{
  width: auto;
  height: auto;
}

.container-skeleton-or-image{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 300px;

  & > img {
    border-radius: 8px;
  }
}

.block-image-list{
  height: 0px;
  padding: 0rem !important;
  display: flex !important;
  flex-direction: column !important;

  & > span {
    padding: 1rem 2rem 0rem 2rem;
  }

  &.show{
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    margin: 0rem 0rem 1rem 0rem !important;
    min-height: 200px !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 991px) {
  .detail-block{
    flex-direction: column;

    & > span {
      margin-bottom: 5px !important;
    }

    & > div {
      width: 100% !important;
    }
  }
}