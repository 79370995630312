.btn-company-profile{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
}

.radio-button{
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #6b6b6b;

  &.active {
    background-color: var(--color-primary);
  }
}
  