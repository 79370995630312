.index-content{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.sidebar{
  position: fixed;
  height: 100vh;
  width: 250px;
  padding: 15px 22px;
  background-color: var(--bg-white);
  transition: .3s all;
  z-index: 4;
}

.content{
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 250px;
  overflow-y: auto;
  padding: 15px 22px;
}

.content > div {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1px) and (max-width: 249px) {
  .index-content{
    flex-direction: column-reverse;
    overflow: auto;
  }

  .sidebar{
    height: 76px;
    position: fixed;
    width: 100%;
    padding: 0px 5px;
    bottom: 0;
    border-top: 1px Solid #e4e4e4;
    z-index: 4;
  }

  .ios-sidebar{
    height: 84px;
  }

  .content{
    display: flex;
    flex: 100%;
    margin-left: 0px;
    padding: 15px 22px 90px 22px;
  }

  .ios-content{
    padding: 62px 22px 94px 22px !important;
  }

  .content > div {
    flex: 1;
  }
}

.backdrop2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semitransparente */
  z-index: -1; /* Asegura que esté en la parte superior de otros elementos */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Color del texto en el fondo oscuro */
  font-size: 24px;

  &.show {
    z-index: 4;
  }
}

/* Estilos para dispositivos móviles pequeños */
@media screen and (min-width: 250px) and (max-width: 370px) {
  .index-content{
    flex-direction: column-reverse;
    overflow: auto;
  }

  .sidebar{
    height: 76px;
    position: fixed;
    width: 100%;
    padding: 0px 5px;
    bottom: 0;
    border-top: 1px Solid #e4e4e4;
    z-index: 4;
  }

  .ios-sidebar{
    height: 84px;
  }

  .content{
    display: flex;
    flex: 100%;
    margin-left: 0px;
    padding: 15px 22px 90px 22px;
  }

  .ios-content{
    padding: 62px 22px 94px 22px !important;
  }

  .content > div {
    flex: 1;
  }
}

@media screen and (min-width: 371px) and (max-width: 575px) {
  .index-content{
    flex-direction: column-reverse;
    overflow: auto;
  }

  .sidebar{
    height: 76px;
    position: fixed;
    width: 100%;
    padding: 0px 5px;
    bottom: 0;
    border-top: 1px Solid #e4e4e4;
    z-index: 4;
  }

  .ios-sidebar{
    height: 84px;
  }

  .content{
    display: flex;
    flex: 100%;
    margin-left: 0px;
    padding: 15px 22px 90px 22px;
  }

  .ios-content{
    padding: 62px 22px 94px 22px !important;
  }

  .content > div {
    flex: 1;
  }
}

/* Estilos para dispositivos móviles y tablets */
@media screen and (min-width: 576px) and (max-width: 767px) {
  .index-content{
    flex-direction: column-reverse;
    overflow: auto;
  }

  .sidebar{
    height: 76px;
    position: fixed;
    width: 100%;
    padding: 0px 22px;
    bottom: 0;
    border-top: 1px Solid #e4e4e4;
    z-index: 4;
  }

  .ios-sidebar{
    height: 84px;
  }

  .content{
    display: flex;
    flex: 100%;
    margin-left: 0px;
    padding: 15px 22px 90px 22px;
  }

  .ios-content{
    padding: 62px 22px 94px 22px !important;
  }

  .content > div {
    flex: 1;
  }
}

/* Estilos para tablets y pantallas pequeñas de escritorio */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .index-content{
    flex-direction: column-reverse;
    overflow: auto;
  }

  .sidebar{
    height: 76px;
    position: fixed;
    width: 100%;
    padding: 0px 22px;
    bottom: 0;
    border-top: 1px Solid #e4e4e4;
    z-index: 4;
  }

  .ios-sidebar{
    height: 84px !important;
  }

  .content{
    display: flex;
    flex: 100%;
    margin-left: 0px;
    padding: 15px 22px 90px 22px;
  }

  .ios-content{
    padding: 62px 22px 94px 22px !important;
  }

  .content > div {
    flex: 1;
  }
}

/* Estilos para pantallas de escritorio medianas */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar{
    top: 0;
    left: 0;
    border-right: 1px Solid #e4e4e4;
    z-index: 4;
  }

  .content > div {
    min-height: 100%;
  }
}

/* Estilos para pantallas de escritorio grandes y televisores */
@media screen and (min-width: 1200px) {
  .sidebar{
    top: 0;
    left: 0;
    border-right: 1px Solid #e4e4e4;
    z-index: 4;
  }

  .content > div {
    min-height: 100%;
  }
}