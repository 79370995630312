.paper{
  transition: background-color 0.8s ease;

  &.info{
    &.light-mode{
      color: #055160 !important;
      border: 1px Solid #055160 !important;
      background-color: #cff4fc !important;
    }

    &.dark-mode{
      color: #cff4fc !important;
      border: 1px Solid #cff4fc !important;
      background-color: #055160 !important; 
    }
  }

  &.bg-app{
    &.light-mode{
      background-color: var(--bg-white) !important;

      &.collaborator-mode {
        background: rgb(245,245,245);
        background: linear-gradient(0deg, rgba(245,245,245,1) 0%, rgba(196,167,238,.9) 95%);
      }
    }

    &.dark-mode{
      background-color: #000 !important;
    }
  }

  &.navbar{
    &.light-mode{
      background-color: rgba(246, 246, 246, .62) !important;
      backdrop-filter: saturate(200%) blur(22px) !important;
      -webkit-backdrop-filter: saturate(200%) blur(22px) !important;
    }

    &.dark-mode{
      background-color: rgba(32, 32, 32, 0.62) !important;
      backdrop-filter: saturate(200%) blur(22px) !important;
      -webkit-backdrop-filter: saturate(200%) blur(22px) !important;
    }
  }

  &.card-content{
    &.light-mode{
      background-color: #fff !important;
    }

    &.dark-mode{
      background-color: #131313 !important; 
    }
  }
}

.paper-rounded{
  border-radius: .5rem;
}
.paper-tl-rounded{
  border-top-left-radius: .5rem;
}
.paper-tr-rounded{
  border-top-right-radius: .5rem;
}
.paper-bl-rounded{
  border-bottom-left-radius: .5rem;
}
.paper-br-rounded{
  border-bottom-right-radius: .5rem;
}