.image-list-calendar{
  width: 100%;
  padding: 0rem;
  max-height: 0;
  border-radius: 8px;
  transition: max-height 0.3s ease, padding .1s ease;

  &.show{
    min-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex: 1;
    padding: 2rem;
  }
}

.grid-image-list{
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  grid-auto-rows: max-content;

  & > button {
    border: none;
  }
}

@media screen and (min-width: 1px) and (max-width: 756px) {
  .grid-image-list{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 756px) and (max-width: 1200px) {
  .grid-image-list{
    grid-template-columns: repeat(4, 1fr);
  }
}