.container-company-profile {
  margin: 0 auto;
  width: 100%;
  max-width: 980px;
}

.container-profile {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 10px;
  align-items: start;
}

.container-calendar{
  max-width: 100% !important;
}

.rbc-toolbar{
  gap: 10px;
}

.rbc-btn-group {
  display: flex !important;
  flex-direction: row !important;

  & > button {
    padding: 0.375rem 0.7rem !important;
  }
}

.rbc-toolbar > :first-child {
  display: block;
}

.rbc-toolbar-label{
  padding: 0px !important;
  font-size: 18px;
  font-weight: 600;
  text-align: left !important;
}

.rbc-btn-group button:nth-child(2),
.rbc-btn-group button:nth-child(4) {
  display: block;
}

.rbc-btn-group button:nth-child(3) {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.card-company-profile {
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  padding: 0.5rem 1rem;
  max-width: 100%;
  width: 100%;
  min-height: 200px;
}

.title-card{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > span {
    font-size: var(--font-size-18);
    color: var(--color-primary);
    display: flex;
    align-items: center;
  }

  & > button{
    border: none;
    background-color: transparent;
    cursor: pointer;

    & > img {
      height: 18px;
      width: auto;
    }
  }
}

.view-generating-content{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-card{
  & > span {
    color: var(--color-dark-tertiary);
  }
}

.btn-close-modal{
  width: 100%;
  border: none;
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 6px;
  padding: 0.25rem 0rem;
  cursor: pointer;
  transition: .3s all;

  &:hover{
    background-color: var(--color-pale-primary);
    color: #000;
  }
}

.firts-container{
  background-color: #fff;
  border-radius: .75rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.name-company-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > h1 {
    width: 100%;
  }
}

.mission-vision-container{
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 20px;
}

.mission-container{
  & > span {
    width: 100%;
    font-size: 16px;
  }
}

.vision-container{
  & > span {
    width: 100%;
    font-size: 16px;
  }
}

.second-container{
  background-color: #fff;
  border-radius: .75rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.market-audience-container{
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 20px;
}

.target-audience-container{
  & > span {
    width: 100%;
    font-size: 16px;
  }
}

.martket-gap-container{
  & > span {
    width: 100%;
    font-size: 16px;
  }
}

.third-container{
  background-color: #fff;
  border-radius: .75rem;
  padding: 1rem;
}

.benefit-proposition-statement-container{
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 20px;
}

.unique-value-proposition-container{
  & > span {
    width: 100%;
    font-size: 16px;
  }
}

.value-statement-container{
  & > span {
    width: 100%;
    font-size: 16px;
  }
}

.ultimate-benefit-container{
  & > span {
    width: 100%;
    font-size: 16px;
  }
}

.action-buttons-container-card{
  display: flex;
  flex-direction: row;

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin: 0px 5px;
    border-radius: 25%;
    transition: .3s all;
  
    &:hover{
      background-color: #dbdbdb;
    }
  }
}

@media screen and (min-width: 250px) and (max-width: 370px) {
  .mission-vision-container, .market-audience-container, .benefit-proposition-statement-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 371px) and (max-width: 575px) {
  .mission-vision-container, .market-audience-container, .benefit-proposition-statement-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .mission-vision-container, .market-audience-container, .benefit-proposition-statement-container {
    grid-template-columns: 1fr;
  }
}