.pill-company{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.company-profile-div{
  width: 100%;
  gap: 10px;
}

.settings-profile-div,
.company-profile-div{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: .5rem .25rem;
  border-radius: 8px;
  cursor: pointer;
  background-color: transparent; 
  transition: .3s all;

  & > svg {
    color: #000;
    font-size: 24px;
  }

  &:hover{
    background-color: #ececec;
  }
}

.name-company-pill {
  color: #000;
  font-size: 14px;
  max-width: 145px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.max-width-name-company-pill-1{
  width: 100px;
}

.btn-change-admin-to-collaborator{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0.25rem;
  border-radius: 8px;
  transition: .3s all;

  &:hover{
    background-color: var(--color-secundary);

    & > svg {
      color: #fff;
    }
  }
}

.icon-change-admin-to-collaborator{
  transform: rotate(90deg);
}

.mobile-pill-company{
  background-color: #ffffff;
  border-radius: 8px;
  color: #000;
  margin-bottom: 1rem;
}