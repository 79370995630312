ul {
  list-style-type: none;
}

li {
  list-style-type: disc; /* Estilo de bullet dot predeterminado */
}

.li_check {
  list-style-type: none; /* Eliminar bullet dot para el elemento con la clase .li_check */
  color: rgb(33, 214, 103);
}

.li_check::before {
  content: '\2713'; /* código unicode del símbolo de check */
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
  