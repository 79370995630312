.body-title{
  font-size: 28px;
  font-weight: 600;

  &.light-mode{
    color: #000;
  }

  &.dark-mode{
   color: #fff; 
  }
}

.body-subtitle{
  font-size: 20px;
  font-weight: 500;

  &.light-mode{
    color: #000;
  }

  &.dark-mode{
   color: #fff; 
  }
}

.body-text{
  font-size: 16px;
  font-weight: 400;

  &.light-mode{
    color: #000;
  }

  &.dark-mode{
   color: #fff; 
  }
}

.body-small{
  font-size: 12px;
  font-weight: 400;

  &.light-mode{
    color: #000;
  }

  &.dark-mode{
   color: #fff; 
  }
}
