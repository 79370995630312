.modal {
  display: flex;
  position: fixed;
  border-radius: 12px;
  z-index: -1;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: .3s all;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  &.show {
    opacity: 1;
    z-index: 5;
  }
}

.react-responsive-modal-modal{
  max-width: 100% !important;
}

.modal-alert-persistent{
  min-height: inherit !important;
  padding: 0rem !important;
  overflow: hidden !important;
}

.modal-sm {
  width: 500px;
  max-height: 600px;
}

.modal-md {
  width: 700px;
  max-height: 800px;
}

.modal-lg {
  width: 800px;
  max-height: 900px;
}

.modal-xl {
  width: 1100px;
  min-height: 700px;
  max-height: 900px;
}

.modal-body{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.modal-body-no-responsive{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.modal-content{
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1.25rem 1.5rem;
}

.modal-footer{
  display: flex;
  height: 36px;
  width: 100%;
}

.alert-content{
  display: flex;
  flex-direction: column;
}

.title-alert-modal{
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-500);
}

.text-alert-modal{
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  color: #3a3a3a;
}

.btn-close-modal-alert{
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 36px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: #fff;
  background-color: var(--color-primary);
  transition: .3s all;
  
  &:hover{
    background-color: var(--color-primary-pale);
    color: #000;
  }
}

.title-modal{
  margin-bottom: 1rem;
}

.action-btn-form{
  display: flex;
  flex-direction: row;
  border-top: 1px Solid #e7e7e7;

  button{
    border: none;
    padding: .75rem .5rem;
    cursor: pointer;
  }

  button:first-child {
    flex: 50%;
    background-color: transparent;
    color: #000;
    transition: .3s all;

    &.one-btn-modal{
      border-radius: 0px 0px 10px 10px;
    }

    &.two-btn-modal{
      border-radius: 0px 0px 0px 10px;
    }

    &:hover{
      background-color: #e7e7e7;
    }
  }

  button:nth-child(2) {
    flex: 50%;
    background-color: var(--color-primary);
    color: #fff;
    font-weight: 400;
    border-left: 1px Solid #e7e7e7;
    transition: .3s all;

    &.btn-disabled {
      background-color: var(--color-primary-pale);
      cursor: auto;
      
      &:hover{
        background-color: var(--color-primary-pale);
      }
    }

    &.two-btn-modal{
      border-radius: 0px 0px 10px 0px;
    }

    &:hover{
      background-color: var(--color-hover-primary);
    }
  }
}

@media screen and (min-width: 1px) and (max-width: 249px) {
  .modal-content{
    padding: .75rem;
  }
}

@media screen and (min-width: 250px) and (max-width: 370px) {
  .modal{
    left: 50%;
  }
  .modal-sm{
    width: 100%;
  }
  .modal-md{
    width: 100%;
  }
  .modal-lg{
    width: 100%;
  }
  .modal-xl {
    width: 100% !important;
    height: 100%;
  }
  .modal-content{
    padding: .75rem;
  }
}

/* Estilos para dispositivos móviles pequeños */
@media screen and (min-width: 371px) and (max-width: 575px) {
  .modal{
    left: 50%;
  }
  .modal-sm{
    width: 100%;
  }
  .modal-md{
    width: 100%;
  }
  .modal-lg{
    width: 100%;
  }
  .modal-xl {
    width: 100% !important;
    height: 100%;
  }
  .modal-content{
    padding: 1rem 1.25rem;
  }
}

/* Estilos para dispositivos móviles y tablets */
@media screen and (min-width: 576px) and (max-width: 767px) {
  .modal{
    left: 50%;
  }
  .modal-sm{
    width: 100%;
  }

  .modal-md{
    width: 100%;
  }

  .modal-lg{
    width: 100%;
  }

  .modal-xl {
    width: 100% !important;
    height: 100%;
  }
}

/* Estilos para tablets y pantallas pequeñas de escritorio */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .modal{
    left: 50%;

  }
  .modal-sm{
    width: 100%;
  }

  .modal-md{
    width: 100%;
  }

  .modal-lg{
    width: 100% !important;
  }

  .modal-xl {
    width: 100% !important;
    height: 100%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .modal-xl {
    width: 100% !important;
    height: 100%;
  }
}

/* Estilos para pantallas de escritorio grandes y televisores */
@media screen and (min-width: 1200px) {
  /* Agrega tus estilos aquí */
}