.combo-box{
  position: relative;
  display: flex;
  flex-direction: column;
}

.combo-box-container{
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
  border-radius: 8px;
  top: 55px;
  z-index: 1001;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  & > button {
    padding: .5rem 1rem;
    background-color: transparent;
    border: none;
    border-top: 1px Solid #E6E6E6;
    text-align: start;
    cursor: pointer;
    transition: .3s all;

    &:hover{
      background-color: #dadada81;
    }
  }

  & > button:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top: none !important;
  }

  & > button:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}