.eye-btn{
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eye-btn:hover{
  opacity: 0.7;
}

.eye-btn:focus{
  outline: none;
}

.eye-btn.active{
  background-color: #f44336;
}

.custom-password-input{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.password-input {
  flex: 1;
  height: 100%;
  border: none;
}

.password-input:focus {
  outline: none;
}