@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --bg-white: rgb(245, 245, 245);
  --color-primary: #6001d0;       /* Color primery */
  --color-secundary: rgb(255, 174, 24);   /* Color secundary */
  --color-tertiary: rgb(2, 13, 59);       /* Color tertiary */
  --color-success: #3abd1c;      /* Color para el botón 'success' */
  --color-cancel: rgb(209, 52, 52);       /* Color para el botón 'cancel' */

  --color-hover-primary:  rgb(137, 47, 255);   /* Color hover primery */
  --color-hover-secundary: rgb(255, 215, 57);  /* Color hover secundary */
  --color-hover-tertiary: rgb(4, 65, 85);      /* Color hover tertiary */
  --color-hover-success: rgb(75, 222, 39);     /* Color hover para el botón 'success' */
  --color-hover-cancel: rgb(222, 92, 92);      /* Color hover para el botón 'cancel' */

  --color-dark-primary: rgb(72, 52, 124);      /* Color primary dark */
  --color-dark-secundary: rgb(176, 133, 60);   /* Color Color secundary dark */
  --color-dark-tertiary: rgb(10, 34, 43);      /* Color Color tertiary más apagado */
  --color-dark-success: rgb(73, 157, 72);      /* Color para el botón 'success' dark */
  --color-dark-cancel: rgb(157, 72, 72);       /* Color para el botón 'cancel' dark */

  --color-primary-pale: rgb(196, 167, 238);      /* Color primary pale */
  --color-secundary-pale: rgb(255, 231, 195);    /* Color Color secundary pale */ 
  --color-tertiary-pale: rgb(132, 141, 170);     /* Color Color tertiary pale */ 
  --color-success-pale: rgb(181, 228, 183);      /* Color para el botón 'success' pale */
  --color-cancel-pale: rgb(230, 173, 173);       /* Color para el botón 'cancel' pale */

  --electric-violet-50: #f5f2ff;
  --electric-violet-100: #eee7ff;
  --electric-violet-200: #ddd2ff;
  --electric-violet-300: #c5aeff;
  --electric-violet-400: #a980ff;
  --electric-violet-500: #904dff;
  --electric-violet-600: #892fff;
  --electric-violet-700: #7716eb;
  --electric-violet-800: #6312c5;
  --electric-violet-900: #5211a1;
  --electric-violet-950: #32076e;
  --color-dark-primary-2: rgb(82, 68, 117);      /* Color primary dark */

  --font-size-48: 48px;    /* font size 48 px */
  --font-size-46: 46px;    /* font size 46 px */
  --font-size-36: 36px;    /* font size 36 px */
  --font-size-34: 34px;    /* font size 34 px */
  --font-size-32: 32px;    /* font size 32 px */
  --font-size-30: 30px;    /* font size 30 px */
  --font-size-28: 28px;    /* font size 28 px */
  --font-size-26: 26px;    /* font size 26 px */
  --font-size-24: 24px;    /* font size 24 px */
  --font-size-22: 22px;    /* font size 22 px */
  --font-size-20: 20px;    /* font size 20 px */
  --font-size-18: 18px;    /* font size 18 px */
  --font-size-16: 16px;    /* font size 16 px */
  --font-size-14: 14px;    /* font size 14 px */
  --font-size-12: 12px;    /* font size 12 px */

  --font-weight-800: 800;  /* font weight 800 */
  --font-weight-700: 700;  /* font weight 700 */
  --font-weight-600: 600;  /* font weight 600 */
  --font-weight-500: 500;  /* font weight 500 */
  --font-weight-400: 400;  /* font weight 400 */
  --font-weight-300: 300;  /* font weight 300 */
  --font-weight-200: 200;  /* font weight 200 */
  --font-weight-100: 100;  /* font weight 100 */
  --backdrop-color: rgba(250, 250, 250, 0.125);
}

/* Global css */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

button{
  outline: none;
}

.bg-transparent{
  background-color: transparent;
}

.p-0{
  padding: 0 !important;
}

.mb-0{
  margin-bottom: 0 !important;
}

.flex-start{
  align-items: flex-start;
}

.border-none{
  border: none !important;
}

.react-responsive-modal-modal{
  margin: 0px 16px !important;
}

.cursor-pointer{
  cursor: pointer;
}

.overflow-scroll{
  overflow-y: scroll;
  overflow-x: hidden;
}

.container-list{
  background-color: #fff;
  border-radius: .73rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.item-list{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px .5rem;
  transition: .3s all;
  background-color: transparent;
  border: none;
  cursor: pointer;

  & .left-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    margin-left: 5px;
  }
  & .right-container{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &:hover{
    background-color: #d8d8d8;
  }
}

.font-size-10{
  font-size: 10px;
}

.rbc-overlay{
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 1px) and (max-width: 991px) {
  .rbc-overlay{
    width: 90% !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
}

.btn-tooltip{
  font-size: 18px !important;
  cursor: pointer;
}

.firts-child{
  border-top-left-radius: .73rem;
  border-top-right-radius: .73rem;
}

.last-child{
  border-bottom-left-radius: .73rem;
  border-bottom-right-radius: .73rem;
}

.only-child{
  border-radius: .73rem;
}

.column-divider{
  border-bottom: 1px Solid #e4e4e4;
}

.text-small-description{
  line-height: 1.5em;
}

input[type="time"], input[type="date"]{
  padding: 2px 6px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #dadada;
}

.customOverlay{
  backdrop-filter: blur(6px);
  background: var(--backdrop-color) !important;
  top: env(safe-area-inset-top) !important;
  bottom: env(safe-area-inset-bottom);
  left: env(safe-area-inset-left);
  right: env(safe-area-inset-right);
}

.customModal{
  border-radius: .75rem;
  padding: 0px !important;
  background-color: var(--bg-white) !important;
}

.fullscreen-modal { 
  background-color: var(--bg-white) !important;
  height: 100vh;
  max-width: 100% !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  -webkit-overflow-scrolling: touch;
}

.detail-post-container{
  max-height: 100%;
  overflow: scroll;
}

@media screen and (min-width: 1px) and (max-width: 991px) {
  .modal-body{
    min-height: 100vh !important;
    padding: 2rem 0rem 1.25rem 0rem;
    overflow: scroll !important;
  }

  .content-views{
    flex: 1;
    display: flex;
    flex-direction: column;
    
    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;

      .modal-content{
        display: flex;
        flex: 1;
        overflow: auto;
      }
  
      .action-btn-form{
        align-items: flex-end;      
      }
    }

    .multi-select-option-container{
      margin: 1rem 5%;
    }
    
    .container-calendar-monthly-post{
      padding: 1.2rem 5%;
    }
    
    .text-area-container{
      margin: 1rem 5%;
    }
  }
}

.container-view{
  padding-top: 56px;
  height: 100vh;
  width: 100%
}

.text-success{
  color: var(--color-success);
}

.text-danger{
  color: var(--color-cancel) !important;
}

.user-container{
  padding: 1rem 0rem 1rem 0rem;
  height: 100% !important;
}

.invalid-answer{
  border: 2px Solid var(--color-cancel) !important;
}

body{
  background: var(--bg-white);
  background-attachment: fixed;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto !important;

  &.bg-dark{
    background-color: #000 !important;
  }
}

.ios-height{
  height: calc(100vh - 47px) !important;
}

#root{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.overflow-hidden{
  overflow: hidden !important;
}

.alert{
  z-index: -1;
  opacity: 0;
  transition: .3s all;

  &.show {
    opacity: 1;
    z-index: 5;
  }
}

.icon-primary{
  color: var(--color-primary);
}

.icon-muted{
  color: #280055;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: #adadad;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.react-reveal{
  animation-fill-mode: backwards !important;
}

.text-muted{
  color: rgb(146, 146, 146) !important;
}

.text-left{
  text-align: left;
}

.range-slider {
  z-index: 0 !important;
}

.mb-0{
  margin-bottom: 0 !important;
}

/* home user */

.text-center{
  text-align: center;
}

.tooltip-icon{
  font-size: 16px !important;
}

.btn-sign-up-disabled{
  background-color: var(--color-tertiary-pale) !important;
}

.range-slider__thumb{
  background: #710ee2 !important;
  z-index: 1 !important;
}

.range-slider__range{
  background: #3d077a !important;
}

.info-company{
  flex: 70%;
  padding: 1rem;
  background-color: #f3f3f3;
  border-radius: 1rem;
}

.info-calendar{
  flex: 30%;
  padding: 1rem;
  background-color: #f3f3f3;
  border-radius: 1rem
}

.home-user-title{
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-600);
  color: var(--color-dark-primary);
}

.home-user-sub-title{
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-500);
  color: var(--color-dark-tertiary);
}

.container-info-company{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  padding-bottom: 3rem;
}

.info-company-colors{
  flex: 50%;
}

.info-company-fonts{
  flex: 50%;
}

.box-color {
  border-radius: 1rem;
  flex: 1 0 45%; /* Ocupa el 50% del ancho del contenedor */
  border: none;
  box-sizing: border-box; /* Incluye el borde en el tamaño total del elemento */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.text-color-list{
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  color: var(--color-dark-tertiary);
}

.info-company-vision{
  flex: 50%;
}

.info-company-brand-tone{
  flex: 50%;
}

.title-detail-info-company{
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  color: var(--color-dark-tertiary);
}

.text-detail-info-company{
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-300);
  color: var(--color-tertiary-pale);
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 100%;
  height: 96%;
}

.box {
  padding: 10px 10px 10px 0px;
  border: 1px solid #00000000;
}

.custom-height{
  height: 96%;
}

.same-dimentions{
 flex: 1 0 auto;
}

.break-word{
  word-wrap: break-word;
}

/* btn */

.btn{
  padding: .25rem .75rem;
  border: none;
  border-radius: .5rem;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  cursor: pointer;
}

.btn-primary{
  background-color: var(--color-primary) !important;
  color: #fff !important;
  transition: .3s all !important;

  &.disabled{
    background-color: var(--color-primary-pale) !important;
  }

  &:hover {
    background-color: var(--color-hover-primary) !important;
  }
}

.btn-secundary{
  background-color: var(--color-secundary) !important;
  color: #fff !important;
  transition: .3s all !important;

  &.disabled{
    background-color: var(--color-secundary-pale) !important;
  }

  &:hover {
    background-color: var(--color-hover-secundary) !important;
  }
}

.btn-success{
  background-color: var(--color-success);
  color: #fff;
  transition: .3s all;
}

.btn-success:hover{
  background-color: var(--color-hover-success);
}

.btn-cancel{
  background-color: var(--color-cancel);
  color: #fff;
  transition: .3s all;
}

.btn-cancel:hover{
  background-color: var(--color-hover-cancel);
}

/* input */

ul {
  list-style-type: none;
}

li {
  list-style-type: disc; /* Estilo de bullet dot predeterminado */
}

.li_check {
  list-style-type: none; /* Eliminar bullet dot para el elemento con la clase .li_check */
  color: var(--color-dark-success);
}

.li_check::before {
  content: '\2713'; /* código unicode del símbolo de check */
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.input-field{
  background-color: #fff;
  color: var(--color-dark-primary);
  font-size: var(--font-size-14);
  border: 2px Solid var(--color-primary-pale);
  padding: 12px 10px;
  margin-bottom: 1rem;
  border-radius: 10px;
  transition: .3s all;
}

.input-field:hover{
  border: 2px Solid var(--color-dark-primary);
}

.input-field:focus{
  border: 2px Solid var(--color-primary);
  outline: none;
}

.field-form{
  min-width: 380px;
  min-height: 50px;

  &.input-disabled{
    background-color: #0000002a;
  }
}

select {
  height: 50px !important;
  height: auto;
}

/* Tools css */

.d-block{
  display: block !important;
}

.d-none{
  display: none;
}

.color-text-primary{
  color: var(--color-primary) !important;
}
.color-text-secundary{
  color: var(--color-secundary) !important;
}
.color-text-tertiary{
  color: var(--color-tertiary) !important;
}

.text-decoration-none{
  text-decoration: none;
}

.text-align-center{
  text-align: center;
}

.text-align-left{
  text-align: left;
}

.text-align-right{
  text-align: right;
}

.d-flex{
  display: flex;
}

.flex-1{
  flex: 1;
}

.flex-row{
  flex-direction: row;
}

.flex-column{
  flex-direction: column;
}

.flex-row-reverse{
  flex-direction: row-reverse;
}

.flex-column-reverse{
  flex-direction: column-reverse;
}

.justify-content-center{
  justify-content: center;
}

.justify-content-evenly{
  justify-content: space-evenly;
}

.justify-content-around{
  justify-content: space-around;
}

.justify-content-between{
  justify-content: space-between;
}

.bold{
  font-weight: 600 !important;
}

.align-items-center{
  align-items: center;
}

.h-100{
  height: 100%;
}

.w-100{
  width: 100%;
}

/* Margins y Paddings por dirección y opciones */

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.mr-2 {
  margin-right: 1rem;
}

.mr-3 {
  margin-right: 1.5rem;
}

.mr-4 {
  margin-right: 2rem;
}

.mr-5 {
  margin-right: 2.5rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.ml-2 {
  margin-left: 1rem;
}

.ml-3 {
  margin-left: 1.5rem;
}

.ml-4 {
  margin-left: 2rem;
}

.ml-5 {
  margin-left: 2.5rem;
}

.pt-1 {
  padding-top: 0.5rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pt-4 {
  padding-top: 2rem;
}

.pt-5 {
  padding-top: 2.5rem;
}

.pr-0 {
  padding-right: 0rem !important;
}

.pr-1 {
  padding-right: 0.5rem;
}

.pr-2 {
  padding-right: 1rem;
}

.pr-3 {
  padding-right: 1.5rem;
}

.pr-4 {
  padding-right: 2rem;
}

.pr-5 {
  padding-right: 2.5rem;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.pb-5 {
  padding-bottom: 2.5rem;
}

.pl-0 {
  padding-left: 0rem !important;
}

.pl-1 {
  padding-left: 0.5rem;
}

.pl-2 {
  padding-left: 1rem;
}

.pl-3 {
  padding-left: 1.5rem;
}

.pl-4 {
  padding-left: 2rem;
}

.pl-5 {
  padding-left: 2.5rem;
}

/* Estilo para deshabilitar la selección de texto */
.unselectable {
  -webkit-user-select: none; /* Para navegadores basados en WebKit, como Chrome y Safari */
  -moz-user-select: none; /* Para navegadores basados en Gecko, como Firefox */
  -ms-user-select: none; /* Para Internet Explorer */
  user-select: none; /* Propiedad estándar */
  cursor: auto !important;
}

.custom-tooltip {
  border-radius: 10px !important;
  max-width: 300px !important;
  z-index: 50 !important;
  background-color: #280055 !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.alert-center-bottom{
  left: 50%;
  transform: translateX(-50%);
}

.bg-white{
  background-color: #fff;
}

.bg-glass{
  background-color: rgba(246, 246, 246, .62) !important;
  backdrop-filter: saturate(200%) blur(22px) !important;
  -webkit-backdrop-filter: saturate(200%) blur(22px) !important;
}

.bg-glass-secondary{
  background-color: rgba(228, 228, 228, .58) !important;
  backdrop-filter: saturate(200%) blur(22px) !important;
  -webkit-backdrop-filter: saturate(200%) blur(22px) !important;
}

.bg-glass-dark{
  background-color: #0009;
  backdrop-filter: saturate(200%) blur(22px) !important;
  -webkit-backdrop-filter: saturate(200%) blur(22px) !important;
}

.bg-glass-dark-secondary{
  background-color: rgba(70, 70, 70, 0.58) !important;
  backdrop-filter: saturate(200%) blur(22px) !important;
  -webkit-backdrop-filter: saturate(200%) blur(22px) !important;
}

.bg-gray{
  background-color: #f3f3f3;
}

.border-radius-1{
  border-radius: .5rem;
}
.border-radius-2{
  border-radius: 1rem;
}
.border-radius-3{
  border-radius: 1.5rem;
}
.border-radius-4{
  border-radius: 2rem;
}
.border-radius-5{
  border-radius: 2.5rem;
}

/* Clases flex-10 */
.flex-10 {
  flex: 10%;
}

.flex-20 {
  flex: 20%;
}

.flex-30 {
  flex: 30%;
}

.flex-40 {
  flex: 40%;
}

.flex-50 {
  flex: 50%;
}

.flex-60 {
  flex: 60%;
}

.flex-70 {
  flex: 70%;
}

.flex-80 {
  flex: 80%;
}

.flex-90 {
  flex: 90%;
}

.flex-100 {
  flex: 100%;
}

.gap-10{
  gap: 10px;
}

.flex-nowrap{
  flex-wrap: nowrap;
}

.flex-wrap{
  flex-wrap: wrap;
}

a {
  text-decoration: none;
  color: #000;
}

.rbc-month-view{
  border-radius: 6px !important;
}

.content-title-embed{
  display: flex;
  width: 100%;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > div:nth-child(1){
    min-width: 90px;
    height: 100%;
  }

  & > div:nth-child(2){
    flex: 1;
  }

  & > div:nth-child(3){
    min-width: 90px;
    height: 100%;
  }
}

.link-back{
  display: flex;
  justify-content: center;
  align-items: center; 
}
