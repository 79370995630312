.container-view-monthly-post{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.container-calendar-monthly-post{
  padding: 1.2rem 3rem;
  height: 300px;
}

.rbc-toolbar{
  flex-direction: row-reverse;
  justify-content: space-between;
}

.rbc-toolbar > span:nth-child(3) {
  display: none !important;
}

.rbc-row-bg, .rbc-month-row{
  cursor: pointer !important;
}

.rbc-off-range-bg{
  cursor: default !important;
}

.day-selected-minicalendar{
  background-color: rgb(196, 167, 238) !important;
  color: #fff !important;
}

.container-group-social-media{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;

  & > button {
    flex: 50%;
  }
}

.card-group-social-media{
  background-color: #fff;
  border: 1px Solid transparent;
  padding: .75rem 1.75rem;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition: .3s all;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 1px) and (max-width: 991px) {
  .container-group-social-media{
    flex-direction: column;
    gap: 10px;

    & > button {
      flex: 100%;
    }
  }
}  